import React from 'react'
import loader from '../../src/assets/img/loader.gif'
export const Loader = () => {
    return (
        <div className="container-fluid  bg_spans text-center">
            <div className="col-lg-2 offset-lg-5 display-1 text-white">
               LOADING
            </div>
        </div>
    )
}
